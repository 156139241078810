import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Title, Text, ResponsiveImage } from "../components/general";
import { Container } from 'react-floating-action-button';

import moneyChangerBanner from '../assets/money-changer-banner.png'
import moneyExchangeIc from '../assets/money-exchange-ic.svg'
import whatsapp from '../assets/whatsapp.png'

const MoneyChanger = props => {

    const lang = useSelector(state => state.language.value)
    const texts = useSelector(state => state.language.texts[lang].MoneyChanger)

    const firebase = useSelector(state => state.firebase)
    const [selectedCurrency, setCurrency] = useState(0)
    const [moneyChangerValues, setMoneyChangerValues] = useState(null)
    const [changedCurrencyValue, setChangedCurrencyValue] = useState('')
    const [inputIDRValue, setIDRValue] = useState('')

    useEffect(() => {
        if (firebase. value) {
            if (firebase.value['money-changer']) {
                setMoneyChangerValues(firebase.value['money-changer'])
                setCurrency(firebase.value['money-changer'].RMB)
            }
        }
    }, [firebase])

    useEffect(() => {
        console.log(moneyChangerValues)
    }, [moneyChangerValues])

    useEffect(() => {
        console.log(changedCurrencyValue, inputIDRValue, selectedCurrency)
    }, [changedCurrencyValue])

    return (
        <div id="money-changer">
            <Container>
                <img  
                onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank')
                }}
                src={whatsapp} 
                style={{ width: '7rem', height: '7rem', cursor: 'pointer'}}
                />
            </Container>
            <ResponsiveImage
                id="hero-cover"
                img={moneyChangerBanner}
                style={{width: '100%', height: '55rem', backgroundPosition: 'center', backgroundSize: 'cover', position: 'relative',}}>
                <div id="hero-cover-freight">
                    <div style={{width: '50rem', textAlign: 'center'}}>
                        <h1>{texts.bannerTitle}</h1>
                        <p>{texts.bannerDesc}</p>
                    </div>
                </div>
            </ResponsiveImage>
            <div id="money-changer-body">
                <div id="money-changer-body-text">
                    <div style={{maxWidth: '35rem',}}>
                        <div style={{width: '8rem', height: '.3rem', background: '#FFB206'}}></div>
                        <Text fontSize={24} lineHeight={42} style={{marginTop: '2rem', fontWeight: 700, color: 'black'}}>
                            {texts.moneyChangerHeader}
                        </Text>
                    </div>
                </div>
                <div id="money-changer-body-changer">
                    <div className="add-shadow" id="money-changer" style={{position: 'relative'}}>
                        <div style={{position: 'absolute', bottom: '2rem', left: '2rem'}}>
                            <p>{texts.moneyChangeNote}</p>
                        </div>
                        <div style={{width: '24rem',}}>
                            {/* <label style={{marginLeft: '2rem', fontSize: '1.6rem', color: 'black', fontWeight: 700, width: '100%', }}>
                                USD
                            </label> */}
                            <select 
                            style={{marginLeft: '2rem', fontSize: '1.6rem', color: 'black', fontWeight: 700}}
                            value={selectedCurrency} 
                            onChange={e => {
                                setCurrency(e.target.value)
                            }}>
                                <option value={moneyChangerValues ? moneyChangerValues.RMB : 0}>RMB</option>
                                <option value={moneyChangerValues ? moneyChangerValues.USD : 0}>USD</option>
                                <option value={moneyChangerValues ? moneyChangerValues.SGD : 0}>SGD</option>
                                <option value={moneyChangerValues ? moneyChangerValues.AUD : 0}>AUD</option>
                            </select>
                            <input
                            id="us-money-input"
                            value={changedCurrencyValue}
                            onChange={e => {
                                let value = e.target.value.replace(/\b0+/g, '')
                                .replace(/[^0-9.]/g, '')
                                .replace(/(\..*?)\..*/g, '$1')
                                setChangedCurrencyValue(value)
                                setIDRValue(Math.round((Number(value) * selectedCurrency) * 100) / 100)
                            }}
                            style={{
                                background: '#FFB206',
                                color: 'white',
                                fontSize: '1.6rem',
                                border: 0,
                                width: '24rem',
                                height: '5rem',
                                borderRadius: '1rem',
                                padding: '1rem',
                                marginTop: '2rem',
                            }}
                            placeholder="Type here" />
                        </div>
                        <div id="exchange-ic">
                            <img src={moneyExchangeIc} />
                        </div>
                        <div style={{width: '24rem',}}>
                            <label style={{marginLeft: '2rem', fontSize: '1.6rem', color: 'black', fontWeight: 700, width: '100%', }}>
                                IDR
                            </label>
                            <input
                            value={inputIDRValue}
                            onChange={e => {
                                let value = e.target.value.replace(/\b0+/g, '')
                                .replace(/[^0-9.]/g, '')
                                .replace(/(\..*?)\..*/g, '$1')
                                setIDRValue(value)
                                setChangedCurrencyValue(Math.round((Number(value) / selectedCurrency) * 100) / 100)
                            }}
                            style={{
                                border: '#FFB206 solid .1rem',
                                fontSize: '1.6rem',
                                background: 'white',
                                width: '24rem',
                                height: '5rem',
                                borderRadius: '1rem',
                                padding: '1rem',
                                marginTop: '2rem',
                            }} placeholder="Type here" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="money-changer-contact-us">
                <Text fontSize={24} style={{fontWeight: 700, color: 'black',}}>{texts.needHelpPrompt}</Text>
                <button onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank');
                }} style={{color: 'white', fontSize: '1.6rem', fontWeight: 800, width: '14.5rem', height: '3.5rem', background: '#4ABCF5', border: 0, marginLeft: '3rem'}}>{texts.contactUsBtnLabel}</button>
            </div>
        </div>
    )
}

export default MoneyChanger