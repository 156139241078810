import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Title, ResponsiveImage, Text } from '../components/general';
import { Container } from 'react-floating-action-button';

import contactBanner from '../assets/contact-banner.png'
import whatsapp from '../assets/whatsapp.png'

const Contact = props => {

    const lang = useSelector(state => state.language.value)
    const texts = useSelector(state => state.language.texts[lang].Contact)

    useEffect(() => {
        console.log(texts)
    }, [lang])

    return (
        <div id="contact">
            <Container>
                <img  
                onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank')
                }}
                src={whatsapp} 
                style={{ width: '7rem', height: '7rem', cursor: 'pointer'}}
                />
            </Container>
            <div id="contact-banner">
                <div id="contact-banner-img">
                    <img src={contactBanner} />
                </div>
                <div id="contact-banner-text" style={{zIndex: 2,}}>
                    <h1 id="contact-banner-title">{texts.bannerTitle}</h1>
                    <p id="contact-banner-desc">{texts.bannerDesc}</p>
                    <button onClick={() => 
            window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank')} id="contact-banner-wa">{texts.waBtn}</button>
                </div>
            </div>
            <div id="contact-text">
                <div style={{marginRight: '5rem'}}>
                    <Title title={texts.needHelpTitle} />
                    <Text fontSize={24} style={{fontWeight: 700, width: '90%', paddingTop: '3rem',}}>
                        {texts.quote}
                    </Text>
                </div>
                <div id="contact-text-info">
                    <Text fontSize={24} style={{fontWeight: 800, width: '90%'}}>
                        Contact
                    </Text>
                    <Text fontSize={16} style={{marginTop: '3rem',}}>
                        +6281272761717
                    </Text>
                    <Text fontSize={16} style={{marginTop: '3rem',}}>
                        ssscargoindonesia@gmail.com
                    </Text>
                    <Text fontSize={16} style={{fontWeight: 800, marginTop: '3rem',}}>
                        CV Sahabat Semua Sukses Cargo
                    </Text>
                    <Text fontSize={16} style={{marginTop: '5rem', maxWidth: '50rem',}}>
                        {texts.description}
                    </Text>
                </div>
            </div>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.8558057891055!2d106.76225141513083!3d-6.150059295547195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f7fa36856909%3A0x5b975eb454add8a!2sCV.%20SSS%20Cargo!5e0!3m2!1sen!2sid!4v1642362266156!5m2!1sen!2sid" width="100%" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"></iframe> */}
        </div>
    )
}

export default Contact