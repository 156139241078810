import React,
{ useState,
    useEffect } from "react";


const RadioBtns = ({initialValue = null, containerStyle, name, radioBtnStyle, radioClassName = '', lists = [], onChange = () => {}}) => {

    return (
    <div style={{display: 'flex', flexWrap: 'wrap', ...containerStyle}}>
        {lists.map(el => <div class={`input-container ${radioClassName}`}
        style={{
            fontSize: '1.6rem',
            fontWeight: 700,
            display: 'flex',
            height: '5rem',
            width: '40%',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            // justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '1rem',
            marginRight: '1rem',
            marginBottom: '1rem',
            ...radioBtnStyle,
        }}>
            <input checked={initialValue && initialValue === el.value} onChange={e => onChange(e)} id={el.value} type="radio" value={el.value} name={name}/>
            <label style={{marginLeft: '10%',}} for={el.value}>{el.name}</label>
        </div>)}
    </div>
    )
}

export default RadioBtns
                