export const moneyChangerTexts = {
    EN: {
        value: 'Money',
        bannerTitle: 'Money Changer.',
        bannerDesc: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is an easy solution for foreign exchange transactions. Providing the best rates, with low transfer fees.',
        moneyChangerHeader: 'SSS Cargo provides an easy solution for foreign exchange transactions.',
        moneyChangeNote: 'Minimum exchange rate per transaction is nominal 1000 USD',
        needHelpPrompt: 'Need Help ?',
        contactUsBtnLabel: 'Contact Us',
    },
    ID: {
        value: 'Money-ID',
        bannerTitle: 'Money Changer.',
        bannerDesc: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) adalah solusi mudah bertransaksi valas. Memberikan rate terbaik, dengan biaya transfer yang rendah.',
        moneyChangerHeader: 'SSS Cargo menyediakan solusi mudah bertransaksi valas. ',
        moneyChangeNote: 'Nilai tukar minimum per transaksi adalah nominal 1000 USD',
        needHelpPrompt: 'Butuh Bantuan ?',
        contactUsBtnLabel: 'Kontak Kami',
    }
}