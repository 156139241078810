import React, {useEffect} from 'react'
import { initializeApp } from "firebase/app";
import { useDispatch } from 'react-redux';
import { getDatabase, ref, onValue, child, get } from "firebase/database";
import './App.css';
import { Navbar, Footer } from './components/general';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes";
import { changeValue } from './reducers/firebaseReducer';

// var admin = require("firebase-admin");

// var serviceAccount = require("./firebase-key.json");

const firebaseConfig = {
    // credential: admin.credential.cert(serviceAccount),
    apiKey: "AIzaSyC29FG8P1FGr5Ut3qaWuYjqO_bt7kuMBWQ",
    authDomain: "sss-cargo.firebaseapp.com",
    databaseURL: "https://sss-cargo-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "sss-cargo",
    storageBucket: "sss-cargo.appspot.com",
    messagingSenderId: "389347784233",
    appId: "1:389347784233:web:12cfc5e4c6072488fc4e38",
    measurementId: "G-BZV7VSWVHY"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const dataRef = ref(database, '/')

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    onValue(dataRef, snapshot => {
      dispatch(changeValue(snapshot.val()))
    })
  }, [])

  return (
    <Router>
        <Navbar/>
        <div id="body">
            <Routes>
            { routes.map( route => {
                return <Route key={ route.path } { ...route } />
              } ) }
            </Routes>
        </div>
        <Footer/>
    </Router>
  );
}

export default App;
