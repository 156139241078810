import { createSlice } from '@reduxjs/toolkit'
import {
  ContactTexts,
  FreightServiceTexts,
  HomeTexts,
  ItemSourcingTexts,
  MoneyChangerTexts,
  ServiceInfoTexts,
  Footer,
} from '../constants/textFiles'

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    value: localStorage.getItem('language') ? localStorage.getItem('language') : 'ID',
    texts: {
      EN: {
        Home: HomeTexts.homeTexts.EN,
        Contact: ContactTexts.contactTexts.EN,
        ItemSourcing: ItemSourcingTexts.itemSourcingTexts.EN,
        MoneyChanger: MoneyChangerTexts.moneyChangerTexts.EN,
        ServiceInfo: ServiceInfoTexts.serviceInfoTexts.EN,
        FreightService: FreightServiceTexts.freightServiceTexts.EN,
        Footer: Footer.footerTexts.EN,
      },
      ID: {
        Home: HomeTexts.homeTexts.ID,
        Contact: ContactTexts.contactTexts.ID,
        ItemSourcing: ItemSourcingTexts.itemSourcingTexts.ID,
        MoneyChanger: MoneyChangerTexts.moneyChangerTexts.ID,
        ServiceInfo: ServiceInfoTexts.serviceInfoTexts.ID,
        FreightService: FreightServiceTexts.freightServiceTexts.ID,
        Footer: Footer.footerTexts.ID,
      },
    }
  },
  reducers: {
    changeLanguage: (state, {payload}) => {
      console.log('test', payload)
      state.value = payload
      localStorage.setItem('language', payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeLanguage } = languageSlice.actions

export default languageSlice.reducer