import React, { useState, useEffect } from "react";
import {Link, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '../../reducers/languageReducer';
import logo from '../../assets/logo.svg';
import indonesia from '../../assets/indonesia.svg';
import unitedKingdom from '../../assets/united-kingdom.svg';
import menuBurger from '../../assets/menu-burger.svg';
import menuBurgerClose from '../../assets/menu-burger-close.svg';


const Navbar = props => {

    const dispatch = useDispatch()

    const location = useLocation();
    const language = useSelector(state => state.language.value)

    const [burgerMode, setBurgerMode] = useState(false)

    useEffect(() => {
        console.log(language)
    }, [language])

    return (
        <>
        <nav id="dashboard-nav" style={{zIndex: 9999}}>
            <div id="dashboard-nav-logo-wrapper">
                <img src={logo}/>
            </div>
            <div id="menu-section" className="show-desktop">
                <Link className={location.pathname === '/' ? 'bold' : null} to="/">HOME</Link>
                <Link className={location.pathname === '/service-info' ? 'bold' : null} to="/service-info">SERVICES</Link>
                <Link className={location.pathname === '/contact' ? 'bold' : null} to="/contact">CONTACT US</Link>
            </div>
            <button onClick={() => setBurgerMode(true)} className="show-mobile" style={{border: 0, background: 'none', cursor: 'pointer', marginRight: '2rem'}}>
                <img src={menuBurger} />
            </button>
            <div id="language-section" className="show-desktop">
                <button onClick={() => {
                    dispatch(changeLanguage('ID'))
                    }}><span className={language === 'ID' ? 'selected bold' : null}>ID <img style={{marginLeft: '.5rem'}} src={indonesia}/></span></button>
                /
                <button onClick={() => {
                    dispatch(changeLanguage('EN'))
                    }}><span className={language === 'EN' ? 'selected bold' : null}>ENG <img style={{marginLeft: '.5rem'}} src={unitedKingdom}/></span></button>
            </div>
        </nav>
        {burgerMode ? <div className="show-mobile" style={{background: 'black', width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 99999}}>
            <button onClick={() => setBurgerMode(false)} style={{ cursor: 'pointer', border: 0, background: 'none', position: "absolute", top: '2rem', right: '2rem'}}>
                <img src={menuBurgerClose} />
            </button>
            
            <div id="language-section-mobile">
                <button style={{ background: 'none', border: 0, color: 'white', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => {
                    dispatch(changeLanguage('ID'))
                    setBurgerMode(false)
                    }}><span className={language === 'ID' ? 'selected bold' : null}>ID <img style={{marginLeft: '.5rem'}} src={indonesia}/></span></button>
                /
                <button style={{ background: 'none', border: 0, color: 'white', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => {
                    dispatch(changeLanguage('EN'))
                    setBurgerMode(false)
                    }}><span className={language === 'EN' ? 'selected bold' : null}>ENG <img style={{marginLeft: '.5rem'}} src={unitedKingdom}/></span></button>
            </div>
            
            <div id="menu-section-mobile">
                <Link onClick={() => setBurgerMode(false)} className={location.pathname === '/' ? 'bold' : null} to="/">HOME</Link>
                <Link onClick={() => setBurgerMode(false)} className={location.pathname === '/service-info' ? 'bold' : null} to="/service-info">SERVICES</Link>
                <Link onClick={() => setBurgerMode(false)} className={location.pathname === '/contact' ? 'bold' : null} to="/contact">CONTACT US</Link>
            </div>
        </div> : null}
        </>
    )
}

export default Navbar
