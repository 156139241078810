export const freightServiceTexts = {
    EN: {
        value: 'Freight',
        bannerTitle: 'Freight Service.',
        bannerDesc: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is here to serve foreign goods delivery services, especially China. The process is easy, fast, and efficient.',
        priceCalculationTitle: "Price Calculation | China - Indonesia",
        modeOfTransportLabel: 'Mode of Transport',
        numberOfContainerLabel: 'Container Load',
        typeOfGoodsLabel: 'Type of Goods',
        volumeLabel: 'Volume',
        calculateBtnLabel: 'CALCULATE',
        priceCalculationDescription: '*Price Calculation: CBM x Type of Goods',
        totalLabel: 'Total',
        needHelpPrompt: "Need Help ? don't hesitate to contact us!",
        contactUsBtnLabel: 'Contact Us',
        quoteFCLBtn: 'For FCL contact us to get price quote'
      },
    ID: {
        value: 'Freight-ID',
        bannerTitle: 'Freight Service.',
        bannerDesc: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) hadir melayani jasa pengiriman barang luar negeri, terutama China. Proses mudah, cepat, dan efisien.',
        priceCalculationTitle: "Perhitungan Harga | China - Indonesia",
        modeOfTransportLabel: 'Transportasi',
        numberOfContainerLabel: 'Kontainer Load',
        typeOfGoodsLabel: 'Jenis Barang',
        volumeLabel: 'Volume',
        calculateBtnLabel: 'HITUNG',
        priceCalculationDescription: '*Perhitungan harga: CBM x Jenis Barang',
        totalLabel: 'Total',
        needHelpPrompt: "Butuh bantuan ? Kontak kami!",
        contactUsBtnLabel: 'Kontak Kami',
        quoteFCLBtn: 'Untuk FCL contact kami untuk dapat harga kusus'
      }
}