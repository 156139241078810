import React,
{ useState,
    useEffect } from "react";


const RadioBtns = ({containerStyle, name, inputStyle, inputClassName = '', lists = [], onChange = () => {}}) => {

    const [values, setValues] = useState({})

    useEffect(() => {
        lists.forEach(el => {
            let obj = values
            obj[el.name] = ''
            setValues(obj)
        })
    }, [])

    return (
    <div style={{display: 'flex', flexWrap: 'wrap', ...containerStyle}}>
        {lists.map(el => <div>
            <label style={{marginLeft: '10%', fontWeight: 700}} for={el.name}>{el.label}</label>
            <input class={`input-container ${inputClassName}`}
            value={values[el.name]}
            onChange={e => {
                let obj = values
                obj[el.name] = e.target.value.replace(/\b0+/g, '')
                .replace(/[^0-9.]/g, '')
                .replace(/(\..*?)\..*/g, '$1')
                onChange(obj)
                // console.log(obj)
                setValues(obj)
            }}
            style={{
                marginTop: '1rem',
                fontSize: '1.6rem',
                display: 'flex',
                height: '5rem',
                width: '40%',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                // justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '1rem',
                marginRight: '1rem',
                marginBottom: '1rem',
                ...inputStyle,
            }}
            id={el.id}
            value={el.value}
            type="number"
            placeholder={el.placeholder || 'Type here..'}
            name={el.name}/>
        </div>)}
    </div>
    )
}

export default RadioBtns
                