import { createSlice } from '@reduxjs/toolkit'
// import { initializeApp } from "firebase/app";
// import { getDatabase, ref, onValue, child, get } from "firebase/database";
// var admin = require("firebase-admin");

// var serviceAccount = require("../firebase-key.json");

// const firebaseConfig = {
//     // credential: admin.credential.cert(serviceAccount),
//     apiKey: "AIzaSyC29FG8P1FGr5Ut3qaWuYjqO_bt7kuMBWQ",
//     authDomain: "sss-cargo.firebaseapp.com",
//     databaseURL: "https://sss-cargo-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "sss-cargo",
//     storageBucket: "sss-cargo.appspot.com",
//     messagingSenderId: "389347784233",
//     appId: "1:389347784233:web:12cfc5e4c6072488fc4e38",
//     measurementId: "G-BZV7VSWVHY"
// };

// const app = initializeApp(firebaseConfig);
// const database = getDatabase(app);
// const dataRef = ref(database, '/')

export const firebaseSlice = createSlice({
  name: 'language',
  initialState: {
    value: null,
  },
  reducers: {
    changeValue: (state, {payload}) => {
      state.value = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeValue } = firebaseSlice.actions

export default firebaseSlice.reducer