import React, { useState, useEffect } from "react";


const Title = ({title}) => {

    return (
        <>
            <h1 className="page-title">{title}</h1>
            <div className="page-title-underline"></div>
        </>
    )
}

export default Title
