export const footerTexts = {
    EN: {
        addressTitle: 'Our Addresses',
        addressValue: [
          'China: Guangzhou, Guangdong Province & Yiwu, Zhejiang Province', 
          'Indonesia: Cengkareng, West Jakarta'
          // 'Guangzhou: \n 广州白云区西槎路恒丰街38号市饮仓38 C \n Guangzhou city bai yun district xi cha road heng feng jie  no.38 block C 101-3'
          // 'Indonesia: \n Gudang Cengkareng: Pergudangan Kedaung, Jl. Peternakan I blok B4 RT 006 RW 001, Kedaung Kali Angke, Cengkareng, West Jakarta, 11710 Gudang Ancol: Jl ancol barat 6 no.3, ancol pademangan, jakarta utara'
        ],
        contactTitle: 'Contact',
        contactNumber: '+6281272761717',
        contactEmail: 'ssscargoindonesia@gmail.com',
      },
    ID: {
        addressTitle: 'Alamat Kami',
        addressValue: [
          'China: Guangzhou, Guangdong Province & Yiwu, Zhejiang Province', 
          'Indonesia: Cengkareng, Jakarta Barat'
        ],
        contactTitle: 'Kontak',
        contactNumber: '+6281272761717',
        contactEmail: 'ssscargoindonesia@gmail.com',
      }
}