import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Title, Text, ResponsiveImage } from "../components/general";
import { LearnMoreBtn } from '../components/pages/ServiceInfo';
import { Container } from 'react-floating-action-button';

import itemSourcingBanner from '../assets/item-sourcing-banner.png'
import whatsapp from '../assets/whatsapp.png'

const ItemSourcing = props => {
    const lang = useSelector(state => state.language.value)
    const texts = useSelector(state => state.language.texts[lang].ItemSourcing)

    useEffect(() => {
        console.log('yo', texts)
    }, [lang])

    return (
        <div id="item-sourcing">
            <Container>
                <img  
                onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank')
                }}
                src={whatsapp} 
                style={{ width: '7rem', height: '7rem', cursor: 'pointer'}}
                />
            </Container>
            <div id="item-sourcing-container">
                <div id="item-sourcing-banner">
                    <ResponsiveImage img={itemSourcingBanner} id="item-sourcing-banner-img" />

                    <div className="show-desktop" style={{width:'100%', marginTop: '3rem', padding: '2rem', display: 'flex', alignItems: 'center'}}>
                        <Text fontSize={16} lineHeight={28} style={{color: 'black', width: '43rem'}}>
                            {texts.otherDescription}
                        </Text>
                        <Text fontSize={24} style={{color: 'black', fontWeight: 700, marginLeft: '3rem',}}>{texts.needHelpPrompt}</Text>
                        <LearnMoreBtn style={{marginLeft: '2rem', marginTop: 0}}/>
                    </div>
                </div>
                <div id="item-sourcing-texts">
                    <Title title={texts.itemSourcingTitle} />
                    <Text fontSize={24} lineHeight={42} style={{color: 'black', fontWeight: 700, marginTop: '1.8rem'}}>
                        {texts.itemSourcingHeader}
                    </Text>
                    <Text fontSize={16} lineHeight={28} style={{color: 'black', marginTop: '3.5rem'}}>
                        {texts.itemSourcingParagraph1}
                    </Text>
                    <Text fontSize={16} lineHeight={28} style={{color: 'black', marginTop: '1.8rem'}}>
                        {texts.itemSourcingParagraph2}
                    </Text>
                </div>
                <div className="show-mobile" style={{margin: '2rem 0', width: '100%', padding: '0 3rem'}}>
                    <Text fontSize={24} style={{color: 'black', fontWeight: 700}}>{texts.needHelpPrompt}</Text>
                    <LearnMoreBtn style={{marginTop: '2rem'}}/>
                </div>
            </div>
            <div className="show-desktop" style={{padding: '15rem'}}>

            </div>
        </div>
    )
}

export default ItemSourcing