import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Title, ResponsiveImage, Text } from '../components/general';
import { RadioBtns, Inputs } from '../components/pages/FreightService';
import { Container } from 'react-floating-action-button';

import FreightServiceBanner from '../assets/freight-service-banner.png'
import whatsapp from '../assets/whatsapp.png'

const FreightService = props => {

    const lang = useSelector(state => state.language.value)
    const texts = useSelector(state => state.language.texts[lang].FreightService)

    const firebase = useSelector(state => state.firebase)
    const [transport, setTransport] = useState(null)
    const [container, setContainer] = useState(null)
    const [typeOfGoods, setTypeOfGoods] = useState(null)
    const [prices, setPrices] = useState({})
    const [volumeDimensionValues, setVolumeDimensionValues] = useState({})
    const [cbmValue, setCbmValue] = useState('')
    const [grandTotal, setGrandTotal] = useState(0)

    useEffect(() => {
        console.log(texts)
    }, [lang])

    useEffect(() => {
        console.log('fire', firebase.value)
        if (transport) {
            setPrices(transport === 'air' ? firebase.value['air-pricing'] : firebase.value['sea-pricing'])
        }
    }, [firebase])

    useEffect(() => {
        console.log('PRICES', prices)
    }, [prices])

    const countCBM = (length, width, height) => {
        setCbmValue(Number(length || 0) * Number(width || 0) * Number(height || 0) || 'calculating...')
        if (!length && !width && !height) setCbmValue('CBM')
    }

    const calculatePrice = () => {
        let allowedWeight
        let cbm = (cbmValue / 1000000) || 0
        let weight = Number(volumeDimensionValues.weight || 0)
        let exceedPricePerCbm = 6000

        if (!transport) return setGrandTotal('Choose a mode of transport')
        if (!typeOfGoods) return setGrandTotal('choose Type of Goods')

        if (transport === 'ocean') {
            allowedWeight = cbm * 700
            console.log(prices[typeOfGoods] || 0, cbm, allowedWeight, weight - allowedWeight)

            if (weight > allowedWeight) {
                let freightPrice = prices[typeOfGoods] * cbm
                
                let exceedWeight = weight - allowedWeight

                let exceedPrice = exceedWeight * exceedPricePerCbm

                let totalPrice = freightPrice + exceedPrice

                setGrandTotal(totalPrice)
                // console.log(totalPrice)
            } else {
                let totalPrice = prices[typeOfGoods] * cbm

                setGrandTotal(totalPrice)
                // console.log(totalPrice)
            }
        } else {
            allowedWeight = cbm  / 6000

            if (allowedWeight > weight) {
                let totalPrice = prices[typeOfGoods] * allowedWeight

                setGrandTotal(totalPrice)
                // console.log(totalPrice)
            } else {
                let totalPrice = prices[typeOfGoods] * weight

                setGrandTotal(totalPrice)
                // console.log(totalPrice)
            }
        }
    }

    const grandTotalRupiah = () => {
        let angka = grandTotal.toString()
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
     
        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if(ribuan){
            let separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
     
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return (rupiah ? 'Rp. ' + rupiah : '') + ',-'
    }

    return (
        <div id="freight-service">
            <Container>
                <img  
                onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank')
                }}
                src={whatsapp} 
                style={{ width: '7rem', height: '7rem', cursor: 'pointer'}}
                />
            </Container>
            <ResponsiveImage
                id="hero-cover"
                img={FreightServiceBanner}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    position: 'relative',
                }}
            >
                <div id="hero-cover-freight">
                    <div style={{width: '50rem', textAlign: 'center'}}>
                        <h1>{texts.bannerTitle}</h1>
                        <p>{texts.bannerDesc}</p>
                    </div>
                </div>
            </ResponsiveImage>
            <div id="page-body">
                <Title title={texts.priceCalculationTitle}/>
                <div id="mode-of-transport-section">
                    <Text fontSize={24} className="freight-service-title" style={{textAlign: 'left'}}>{texts.modeOfTransportLabel}</Text>
                    <RadioBtns
                    onChange={e => {
                        setTypeOfGoods(null)
                        setTransport(e.target.value)
                        setPrices(e.target.value === 'air' ? firebase.value['air-pricing'] : firebase.value['sea-pricing'])
                    }}
                    radioClassName="add-shadow"
                    name="modeOfTransport"
                    radioBtnStyle={{width: '23.9rem', border: 0}}
                    containerStyle={{
                        width: '70%',
                        marginLeft: '2.5rem',
                    }}
                    lists={[{
                        value: 'ocean',
                        name: 'Ocean Freight',
                    }, {
                        value: 'air',
                        name: 'Air Freight'
                    }]}/>
                </div>
                <div id="freight-service-price-calculation-body">
                    <div className="freight-service-price-calculation-section">
                        <Text fontSize={20} className="freight-service-title">{texts.numberOfContainerLabel}</Text>
                        <RadioBtns
                            onChange={e => setContainer(e.target.value)}
                            name="numberOfContainer"
                            containerStyle={{marginTop: '3rem', justifyContent: 'center',}}
                            radioBtnStyle={{width: '30rem'}}
                            lists={transport == "ocean" ? [{
                                    value: 'lcl',
                                    name: 'LCL (Less Container Load)'
                                },
                                {
                                    value: 'fcl',
                                    name: 'FCL (Full Container load)'
                                }] 
                                : 
                                [{
                                    value: 'lcl',
                                    name: 'LCL (Less Container Load)'
                            }]}/>
                    </div>
                    {container != "fcl" &&
                    <div className="freight-service-price-calculation-section">
                        <Text fontSize={20} className="freight-service-title" style={{marginTop: '3rem',}}>{texts.typeOfGoodsLabel}</Text>
                        <RadioBtns
                            initialValue={typeOfGoods}
                            onChange={e => setTypeOfGoods(e.target.value)}
                            name="typeOfGoods"
                            containerStyle={{marginTop: '3rem', justifyContent: 'center',}}
                            radioBtnStyle={{width: '30rem'}}
                            lists={[
                                {
                                    value: 'general',
                                    name: 'Barang Umum'
                                },
                                ...(transport !== 'air' ? [{
                                    value: 'garment',
                                    name: 'Garment / Semi Garment'
                                }] : []),
                                {
                                    value: 'electronic',
                                    name: 'Electronic / SNI'
                                },
                                ...(transport !== 'air' ? [{
                                    value: 'accessories',
                                    name: 'Makan / Cosmetic'
                                },
                                // {
                                //     value: 'food',
                                //     name: 'Makanan'
                                // },
                                {
                                    value: 'etc',
                                    name: 'Barang Lainnya'
                                }] : [])
                            ]}
                        />
                    </div>}
                    {container != "fcl" &&
                    <div className="freight-service-price-calculation-section">
                        <Text fontSize={20} className="freight-service-title" style={{marginTop: '3rem',}}>{texts.volumeLabel}</Text>
                        <Inputs
                            containerStyle={{marginTop: '3rem', justifyContent: 'center',}}
                            inputStyle={{width: '30rem'}}
                            onChange={val => {
                                console.log('hai', volumeDimensionValues)
                                countCBM(val.length, val.width, val.height)
                                setVolumeDimensionValues(val)
                            }}
                            lists={[
                                {
                                    id: 'length',
                                    name: 'length',
                                    label: 'Length (cm)',
                                },
                                {
                                    id: 'width',
                                    name: 'width',
                                    label: 'Width (cm)',
                                },
                                {
                                    id: 'height',
                                    name: 'height',
                                    label: 'Height (cm)',
                                },
                                {
                                    id: 'weight',
                                    name: 'weight',
                                    label: 'Weight (kg)',
                                },
                            ]}
                        />
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div id="cbm-input-wrappper">
                                <label style={{marginLeft: '10%', width: '100%', fontWeight: 700}}>CBM (m3)</label>
                                <input value={typeof cbmValue === 'string' ? cbmValue : (cbmValue / 1000000)} disabled id="cbm-input" placeholder="CBM"/>
                            </div>
                        </div>
                    </div>}
                    { container == "fcl" ? 
                        <div id="freight-service-grand-total-section">
                            <button onClick={() => {
                                window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20freight-service%20FCL', '_blank')
                            }} id="calculate-button">{texts.quoteFCLBtn}</button>
                        </div> 
                        :
                        <div id="freight-service-grand-total-section">
                            <button onClick={() => calculatePrice()} id="calculate-button">{texts.calculateBtnLabel}</button>

                            <Text fontSize={16} style={{color: '#646465', marginTop: '2rem'}}>{texts.priceCalculationDescription}</Text>
                            <div id="grand-total">
                                <Text id="total-text">{texts.totalLabel}: </Text>
                                <Text id="grand-total-number">{`${ typeof grandTotal === 'string' ? grandTotal : grandTotalRupiah()}`}</Text>
                            </div>
                        </div> 
                    }
                    <div id="freight-service-contact-us">
                        <Text fontSize={24} style={{fontWeight: 700}}>{texts.needHelpPrompt}</Text>
                        <button onClick={() => {
                            window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank');
                        }} id="freight-service-contact-us-btn">{texts.contactUsBtnLabel}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FreightService