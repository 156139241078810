export const homeTexts = {
    EN: { 
        value: 'Home',
        moto: 'We Deliver Continuous Excellence. The Best Cargo Solution to Grow Your Business',
        motoDescription: 'Your every business deserves exceptional cargo service. Build your success story with us. Cargo friends who can always be relied on',
        contactUsBtn: 'Contact Us',
        companyParagraph: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is your comfort solution who is always there to meet your Import needs and build your business better',
        titleAboutUs: 'About Us.',
        titleServices: 'Services.',
        titleWhyChooseUs: "Why Choose Us?",
        aboutUsParagraph: [
          'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is an expert in Freight Forwarding with Import shipping services that are very professional in their fields. We are ready to deliver your shipment safely and on time. Quick response service anytime and anywhere. You will save a lot of energy and time, so you can focus more on managing and expanding your business',
          'Based in a strategic location, we are able to provide top-notch services and optimal facilities. With competitive prices, we adapt to your needs through shipping options via sea (Sea Freight) and Air (Air Freight) with a coverage area of ​​China, Bangkok, and Singapore.'
        ],
        freightHeader: 'Freight Service',
        freightDescription: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is the best solution for your import shipments. Our advantages provide competitive prices, service flexibility by sea and air. As well as door to door service that transports and delivers goods to the front door.',
        itemSourcingHeader: 'Item Sourcing',
        itemSourcingDescription: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is the best solution for shopping in foreign marketplaces expecially China, with extra protection. We help to the end! As easy as the click of a finger, you will find it easier to find a variety of goods from China, Bangkok, and Singapore.',
        moneyChangerHeader: 'Money Changer',
        moneyChangerDescription: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is the best and easy solution for foreign exchange transactions. We provide the best rate for you. Transactions with us are safe and secure with affordable minimum charges and cheap extra charges.',
        ourVisionTitle: 'Our Vision',
        ourVisionParagraph: [
          'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) dreams of being the best sea and air forwarding service in Indonesia. Providing world-class services with professional staff who provide fast and appropriate solutions. We cultivate commitment and trust, like friends.',
          'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is ready to be at the forefront, the face of Indonesian cargo in the future. Improving information technology that connects import activities without distance restrictions. To be one of the safest and easiest freight forwarding operators in the industry.'
        ],
        ourMissionTitle: 'Our Mission',
        ourMissionParagraph: [
          `Creating sophisticated systems, with application support and high flexibility. Build the best feature service in the distribution of goods and cargo. Offering competitive prices so it's possible to use our services forever.`,
          'Hire the right people with a strong work ethic, who are able to provide quick and precise solutions.',
          'Provide reliable, transparent, and trusted services as a guarantee of security and customer satisfaction.'
        ],
    },
    ID: {
        value: 'Home-ID',
        moto: 'We Deliver Continuous Excellence. The Best Cargo Solution to Grow Your Business',
        motoDescription: 'Setiap usaha Anda berhak atas layanan kargo yang luar biasa. Bangun kisah suksesmu Bersama kami. Sahabat kargo yang selalu dapat diandalkan',
        contactUsBtn: 'Kontak Kami',
        companyParagraph: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) adalah sahabat terbaik yang selalu ada untuk memenuhi kebutuhan Import dan membangun usaha Anda lebih baik',
        titleAboutUs: 'Tentang Kami.',
        titleServices: 'Jasa Kami.',
        titleWhyChooseUs: "Kenapa memilih Kami?",
        aboutUsParagraph: [
          'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) adalah ahlinya Freight Forwarding dengan jasa pengiriman Impor yang sangat profesional di bidangnya. Kami siap mengantar kiriman Anda dengan aman dan tepat waktu. Layanan respon cepat kapan saja dan dimana saja. Anda akan menghemat banyak tenaga dan waktu, sehingga lebih fokus mengelola dan memperluas bisnis Anda',
          'Berkantor di lokasi strategis, kami mampu memberikan layanan jempolan dan fasilitas optimal. Dengan harga kompetitif, kami menyesuaikan kebutuhan Anda melalui pilihan pengiriman via jalur laut (Sea Freight) maupun Udara (Air Freight) dengan jangkauan wilayah China, Bangkok, dan Singapura.'
        ],
        freightHeader: 'Jasa Kargo',
        freightDescription: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) adalah solusi terbaik untuk pengiriman impor anda. Keunggulan kami memberikan harga kompetitif, fleksibilitas layanan melalui jalur laut dan udara. Serta door to door service yang mengangkut dan mengantar barang sampai depan pintu.',
        itemSourcingHeader: 'Sourcing Barang',
        itemSourcingDescription: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) adalah solusi terbaik berbelanja di marketplace China, dengan proteksi ekstra. Kami bantu sampai tuntas! Semudah klik 1 jari, Anda semakin mudah menemukan beragam kebutuhan barang dari China, Bangkok, dan Singapura.',
        moneyChangerHeader: 'Penukaran Uang',
        moneyChangerDescription: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) adalah solusi terbaik dan mudah untuk bertransaksi valas. Kami memberikan rate terbaik untuk Anda. Transaksi Bersama kami, aman dan terjamin dengan minumum charge yang terjangkau dan extra charge yang murah.',
        ourVisionTitle: 'Visi Kami',
        ourVisionParagraph: [
          'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) mengemban cita-cita menjadi jasa forwarding laut dan udara terbaik di Indonesia. Memberikan pelayanan kelas dunia dengan tenaga professional yang memberi solusi cepat dan tepat. Kami memupuk komitmen dan kepercayaan, layaknya sahabat.',
          'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) siap menjadi yang terdepan, wajah kargo Indonesia di masa depan. Meningkatkan informasi teknologi yang menghubungkan kegiatan impor tanpa batasan jarak. Menjadi salah satu operator freight forwarding paling aman dan mudah di industri ini.'
        ],
        ourMissionTitle: 'Misi Kami',
        ourMissionParagraph: [
          'Menciptakan sistem canggih, dengan dukungan aplikasi dan fleksibilitas tinggi. Membangun layanan fitur terbaik dalam pendistribusian barang dan kargo. Menawarkan harga yang kompetitif sehingga mungkin memakai jasa kami untuk selamanya.',
          'Mempekerjakan orang yang tepat dengan etos kerja kuat, yang mampu memberikan solusi cepat dan tepat.',
          'Memberikan layanan yang andal, transparan, dan terpercaya sebagai jaminan kemanan dan kepuasan pelanggan.',
        ],
    }
}