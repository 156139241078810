import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Title, ResponsiveImage, Text } from '../components/general';
import { LearnMoreBtn } from '../components/pages/ServiceInfo';
import { Container } from 'react-floating-action-button';

import heroImg from '../assets/hero.png'
import storageServices from '../assets/storage-services.png'
import freightServices from '../assets/freight-service.png'
import moneyChanger from '../assets/money-changer.png'
import sourcingItem from '../assets/sourcing-item.png'
import visionMission from '../assets/vision-mission.png'
import whatsapp from '../assets/whatsapp.png'

const Home = props => {

    const lang = useSelector(state => state.language.value)
    const texts = useSelector(state => state.language.texts[lang].Home)

    useEffect(() => {
        console.log(texts)
    }, [lang])
    return (
        <div id="home">
            <Container>
                <img  
                onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank')
                }}
                src={whatsapp} 
                style={{ width: '7rem', height: '7rem', cursor: 'pointer'}}
                />
            </Container>
            <ResponsiveImage
                id="hero-cover"
                img={heroImg}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    color: 'white',
                }}
            >
                <div id="hero-cover-content">
                    <h1>{texts.moto}</h1>
                    <p>{texts.motoDescription}</p>
                    <div>
                        <LearnMoreBtn routing='/service-info'/>
                        <button onClick={() => window.location.href='/contact'} id="hero-cover-contact-us-btn">
                            {texts.contactUsBtn}
                        </button>
                    </div>
                </div>
            </ResponsiveImage>
            <div id="about-us-wrapper">
                <div className="about-us-section">
                    <Title title={texts.titleAboutUs}/>
                    <div style={{marginTop: '4rem',}}>
                        <Text fontSize={24} lineHeight={42} style={{fontWeight: 700,}}>
                            {texts.companyParagraph}
                        </Text>
                    </div>
                </div>
                <div className="about-us-section">
                    {texts.aboutUsParagraph.map((el, i) => i ? 
                    <Text fontSize={16} lineHeight={28} style={{marginTop: '2.8rem'}}>{el}</Text> : <Text fontSize={16} lineHeight={28}>{el}</Text>)}
                    
                </div>
            </div>

            <div id="home-services-wrapper">
                <ResponsiveImage 
                    id="home-services-image"
                    img={storageServices}
                >
                    <div style={{width: '100%', height: '100%', background: 'rgba(0, 0, 0, .4)'}}></div>
                </ResponsiveImage>
                <div id="home-services-body-wrapper">
                    <Title title={texts.titleServices}/>
                    <div id="home-services-service-section-wrapper">
                        <div onClick={() => window.location.href='/freight'} className="home-services-service-section">
                            <img  src={freightServices} />
                            <Text lineHeight={35} style={{fontWeight: 800, paddingTop: '2rem', paddingBottom: '2rem'}}>
                                {texts.freightHeader}
                            </Text>
                            <Text lineHeight={28} fontSize={16}>{texts.freightDescription}</Text>
                        </div>
                        <div onClick={() => window.location.href='/item-sourcing'} className="home-services-service-section">
                            <img src={sourcingItem} />
                            <Text lineHeight={35} style={{fontWeight: 800, paddingTop: '2rem', paddingBottom: '2rem'}}>
                                {texts.itemSourcingHeader}
                            </Text>
                            <Text lineHeight={28} fontSize={16}>{texts.itemSourcingDescription}</Text>
                        </div>
                        <div onClick={() => window.location.href='/money-changer'} className="home-services-service-section">
                            <img src={moneyChanger} />
                            <Text lineHeight={35} style={{fontWeight: 800, paddingTop: '2rem', paddingBottom: '2rem'}}>
                                {texts.moneyChangerHeader}
                            </Text>
                            <Text lineHeight={28} fontSize={16}>{texts.moneyChangerDescription}</Text>
                        </div>
                    </div>
                </div>
            </div>

            <div id="home-why-choose-us-wrapper">
                <div id="home-why-choose-us-title">
                    <Title title={texts.titleWhyChooseUs}/>
                </div>
                <div id="home-why-choose-us-body-wrapper">
                    <div id="home-why-choose-us-text-wrapper">
                        <div id="home-vision">
                            <p className="home-why-choose-us-text-title">{texts.ourVisionTitle}</p>
                            <div className="home-why-choose-us-text-paragraph-wrapper">
                                {texts.ourVisionParagraph.map((el, i) => i ? 
                                <Text fontSize={16} lineHeight={28} style={{marginTop: '2rem'}}>{el}</Text> : <Text fontSize={16} lineHeight={28}>{el}</Text>)}
                            </div>
                        </div>
                        <div id="home-mission">
                            <p className="home-why-choose-us-text-title">{texts.ourMissionTitle}</p>
                            <div className="home-why-choose-us-text-paragraph-wrapper">
                                {texts.ourMissionParagraph.map((el, i) => i ? 
                                <Text fontSize={16} lineHeight={28} style={{marginTop: '2rem'}}>{el}</Text> : <Text fontSize={16} lineHeight={28}>{el}</Text>)}
                            </div>
                        </div>
                    </div>
                    <ResponsiveImage id="home-vision-mission-img" img={visionMission} />
                </div>
            </div>
        </div>
    )
}

export default Home