import React, { useState, useEffect } from "react";

const LearnMoreBtn = ({onClick = () => {}, style = {}, routing}) => {

    return (
    <button onClick={() => {
        onClick()
        if(routing) {
            window.location.href=routing
        } else {
            window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank');
        }
    }} style={{marginTop: '3rem', cursor: 'pointer', color: 'white', background: '#4ABCF5', fontSize: '1.6rem', fontWeight: 700, border: 0, padding: '1rem', ...style}}>
        Learn More
    </button>
    )
}

export default LearnMoreBtn
                