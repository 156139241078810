export const itemSourcingTexts = {
    EN: {
        value: 'Items',
        itemSourcingTitle: "Item Sourcing.",
        itemSourcingHeader: 'Trust your import purchases, with SSS Cargo.',
        itemSourcingParagraph1: 'We help to the end! Starting from the search for goods, transactions, to delivery of goods to your address. SSS Cargo provides trading services/imports goods from China, Bangkok, and Singapore. We want to build long-term partnerships that add value to all parties',
        itemSourcingParagraph2: 'Let us do the work for you. We are ready to assist in the search for goods, transactions, quality checks, to delivery of goods. Just provide the desired product information, our professional team will move to contact the supplier regarding the price and availability of goods. The shopping process is easier and faster to reach your location!',
        otherDescription: 'Do you have questions about our services? Call us right now!',
        needHelpPrompt: 'Need Help ?',
      },
    ID: {
        value: 'Items-ID',
        itemSourcingTitle: "Sourcing Barang.",
        itemSourcingHeader: 'Percayakan pembelanjaan impor Anda, bersama SSS Cargo.',
        itemSourcingParagraph1: 'Kami bantu sampai tuntas! Mulai dari pencarian barang, transaksi, hingga pengiriman barang ke alamat Anda. SSS Cargo menyediakan jasa trading/impor barang dari China, Bangkok, dan Singapura. Kami mau membangun kemitraan jangka panjang yang memberi nilai tambah bagi semua pihak',
        itemSourcingParagraph2: 'Biarkan kami yang bekerja untuk anda. Kami siap membantu dalam pencarian barang, transaksi, cek kualitas, hingga pengiriman Barang.  Cukup berikan informasi produk yang diinginkan, tim professional kami akan bergerak menghubungi supplier terkait harga dan ketersedian barang. Proses pembelanjaan lebih mudah dan cepat sampai ke lokasi Anda!',
        otherDescription: 'Anda punya pertanyaan seputar layanan kami? Hubungi kami sekarang juga!',
        needHelpPrompt: 'Butuh Bantuan ?',
      }
}