import React, { useState, useEffect } from "react";
import {Link, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '../../reducers/languageReducer';
import logo from '../../assets/logo.svg';
import indonesia from '../../assets/indonesia.svg';
import unitedKingdom from '../../assets/united-kingdom.svg';


const Footer = props => {

    const dispatch = useDispatch()

    const location = useLocation();
    const language = useSelector(state => state.language.value)
    const texts = useSelector(state => state.language.texts[language].Footer)

    useEffect(() => {
        console.log(language)
    }, [language])

    return (
        <footer id="footer">
            <div className="footer-section">
                <h2>{texts.addressTitle}</h2>
                {texts.addressValue.map(el => <p>{el}</p>)}
            </div>
            <div className="footer-section">
                <h2>{texts.contactTitle}</h2>
                <p>{texts.contactNumber}</p>
                <p>{texts.contactEmail}</p>
            </div>
            <div className="footer-section">
                <p>Copyright© 2021 SSS Cargo. All rights reserved</p>
                <p>Cv. Semua Sahabat Sukses Cargo</p>
            </div>
        </footer>
    )
}

export default Footer
