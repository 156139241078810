import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Title, ResponsiveImage, Text } from '../components/general';
import { LearnMoreBtn } from '../components/pages/ServiceInfo';
import { Container } from 'react-floating-action-button';


import FreightServiceShowcase from '../assets/freight-service-showcase.png'
import ItemSourcingPic1 from '../assets/item-sourcing-pic1.png'
import ItemSourcingPic2 from '../assets/item-sourcing-pic2.png'
import MoneyChangerPic1 from '../assets/money-changer-pic1.png'
import MoneyChangerPic2 from '../assets/money-changer-pic2.png'
import whatsapp from '../assets/whatsapp.png'

const ServiceInfo = props => {
    const lang = useSelector(state => state.language.value)
    const texts = useSelector(state => state.language.texts[lang].ServiceInfo)

    useEffect(() => {
        console.log(texts)
    }, [lang])

    return (
        <div id="service-info">
            <Container>
                <img  
                onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=6281272761717&text=halo%20sss-cargo,%20saya%20ingin%20bertanya%20tentang%20info%20lebih', '_blank')
                }}
                src={whatsapp} 
                style={{ width: '7rem', height: '7rem', cursor: 'pointer'}}
                />
            </Container>
            <div id="service-info-banner">
                <ResponsiveImage id="service-info-banner-img" img={FreightServiceShowcase} />
                <div id="service-info-banner-text">
                    <Title title={texts.freightServiceTitle} />
                    <Text fontSize={24} lineHeight={42} style={{fontWeight: 700, paddingTop: '3rem',}}>
                        {texts.freightServiceHeader}
                    </Text>
                    <Text fontSize={16} lineHeight={28} style={{fontWeight: 500, marginTop: '4rem',}}>
                        {texts.freightServiceDescription}
                    </Text>
                    <LearnMoreBtn routing='/freight'/>
                </div>
            </div>

            <div id="service-info-item-sourcing">
                <div className="service-info-item-sourcing-info-section">
                    <ResponsiveImage style={{height: '40rem', backgroundPosition: 'left bottom', backgroundSize: 'cover',}} img={ItemSourcingPic1}/>
                </div>
                <div className="service-info-item-sourcing-info-section">
                    <ResponsiveImage style={{height: '40rem', backgroundPosition: 'left bottom', backgroundSize: 'cover',}} img={ItemSourcingPic2}/>
                </div>
                <div className="service-info-item-sourcing-info-section text">
                    <div className="service-info-item-sourcing-info-section-text-body">
                        <div id="service-info-item-sourcing-info-section-text-title">
                            <div>
                                <Title title={texts.itemSourcingTitle} />
                            </div>
                        </div>
                        <Text fontSize={24} lineHeight={42} style={{marginTop: '3rem', fontWeight: 700, paddingLeft: '10rem', paddingRight: '10rem'}}>
                            {texts.itemSourcingHeader}
                        </Text>
                    </div>
                </div>
                <div className="service-info-item-sourcing-info-section text">
                    <div className="service-info-item-sourcing-info-section-text-body">
                        <Text fontSize={16} lineHeight={28} style={{fontWeight: 500}}>
                            {texts.itemSourcingDescription}
                        </Text>
                        <LearnMoreBtn routing='/item-sourcing'/>
                    </div>
                </div>
            </div>

            <div id="service-info-money-changer-info-wrapper">
                <div id="service-info-money-changer-info-img-wrapper">
                    <ResponsiveImage img={MoneyChangerPic1} className="image" />
                    <ResponsiveImage img={MoneyChangerPic2} className="image" />
                </div>
                <div id="service-info-money-changer-info-text-wrapper">
                    <div>
                        <Title title={texts.moneyChangerTitle} />
                    </div>
                    <div style={{width: '100%', marginTop: '5rem',}}>
                        <div style={{width: '100%',}}>
                            <div id="service-info-money-changer-info-header-wrapper">
                                <Text fontSize={24} lineHeight={42} style={{fontWeight: 700}}>
                                    {texts.moneyChangerHeader}
                                </Text>
                            </div>
                        </div>
                        <div id="service-info-money-changer-info-body-wrapper" style={{width: '100%', display: 'flex', marginTop: '5rem',}}>
                            <div className="service-info-money-changer-info-text-info-wrapper">
                                <Text fontSize={16} lineHeight={28} style={{fontWeight: 500}}>
                                    {texts.moneyChangerParagraph1}
                                </Text>
                            </div>
                            <div className="service-info-money-changer-info-text-info-wrapper">
                                <Text fontSize={16} lineHeight={28} style={{fontWeight: 500}}>
                                    {texts.moneyChangerParagraph2}
                                </Text>
                            </div>
                        </div>
                        <LearnMoreBtn routing='/money-changer'/>
                    </div>
                </div>
            </div>
            {/* MARK - TBD
                <div id="service-info-terms-and-conditions">
                <div id="service-info-terms-and-conditions-title-section">
                    <div>
                        <Title title={texts.tocTitle} />
                    </div>
                    <div style={{width: '100%', marginTop: '3rem'}}>
                        <Text fontSize={24} lineHeight={42} style={{fontWeight: 700}}>
                            {texts.tocHeader}
                        </Text>
                    </div>
                </div>
                <div id="service-info-terms-and-conditions-body-section">
                    <div id="body">
                        <Text fontSize={24} lineHeight={42} style={{fontWeight: 800}}>
                            {texts.tocHeaderTitle}
                        </Text>
                        {texts.tocParagraphs.map(el => <Text fontSize={16} lineHeight={28} style={{marginTop: '3rem'}}>
                            {el}
                        </Text>)}
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default ServiceInfo