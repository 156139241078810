export const contactTexts = {
    EN: {
        value: 'Contact',
        bannerTitle: 'Please Get In Touch.',
        bannerDesc: 'Your Satisfaction is Our Happiness',
        waBtn: 'Go to Whatsapp',
        needHelpTitle: "Need Help?",
        quote: 'We take joy in your satisfaction',
        description: 'Are you interested in our services? Please contact us Now! Our super friendly staff will help you get the services you need. Visit us and see how we serve you',
      },
    ID: {
        value: 'Contact-ID',
        bannerTitle: 'Mohon Hubungi Kami.',
        bannerDesc: 'Kepuasan Anda adalah Kebahagiaan Kami',
        waBtn: 'Pergi ke Whatsapp',
        needHelpTitle: "Butuh Bantuan?",
        quote: 'Kepuasan Anda adalah Kebahagiaan Kami',
        description: 'Anda berminat dengan layanan kami ? Silakan hubungi kami Sekarang! Staf kami yang sangat ramah akan membantu Anda mendapat jasa pelayanan yang sesuai kebutuhan. Kunjungi kami dan lihat bagaimana kami melayani Anda',
      }
}