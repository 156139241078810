import React, { useState, useEffect } from "react";


const Text = ({fontSize=16, lineHeight, className=null, id=null, style = {}, children}) => {

    return (
        <p className={className} id={id} style={{
            fontSize: `${fontSize/10}rem`,
            ...(lineHeight ? {lineHeight: `${lineHeight/10}rem`} : {}),
            ...style
        }}>
            {children}
        </p>
    )
}

export default Text
