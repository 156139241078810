import {
    Home,
    Contact,
    FreightService,
    ItemSourcing,
    MoneyChanger,
    ServiceInfo,
} from '../pages'

const routes = [
    {
        path: '/',
        element: <Home/>,
        exact: true,
        default: true
    },
    {
        path: '/contact',
        element: <Contact />,
        exact: true,
        default: true
    },
    {
        path: '/freight',
        element: <FreightService />,
        exact: true,
        default: true
    },
    {
        path: '/item-sourcing',
        element: <ItemSourcing />,
        exact: true,
        default: true
    },
    {
        path: '/money-changer',
        element: <MoneyChanger />,
        exact: true,
        default: true
    },
    {
        path: '/service-info',
        element: <ServiceInfo />,
        exact: true,
        default: true
    },
    
]

export default routes;
