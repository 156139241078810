export const serviceInfoTexts = {
    EN: {
        value: 'Service',
        freightServiceTitle: "Freight Service.",
        freightServiceHeader: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is here to serve goods delivery services to China, Bangkok, and Singapore. The process is easy, fast, and efficient.',
        freightServiceDescription: 'Serving import transportation services by air and sea. We also provide a calculator so that you can directly get a price quote from us',
        itemSourcingTitle: "Item Sourcing.",
        itemSourcingHeader: `SSS (Cv. Semua Sahabat Sukses Cargo) Cargo is the best shopping companion in international marketplaces, with extra protection. We will help you complete the shopping process and have it delivered to your location!`,
        itemSourcingDescription: 'With us, all shopping becomes very easy. Provides trading/import services from China, Bangkok, and Singapore. The best and most trusted service in finding goods, transactions, checking quality and quantity, to delivery of goods',
        moneyChangerTitle: "Money Changer.",
        moneyChangerHeader: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) is an easy solution for foreign exchange transactions. Providing the best rates, with low transfer fees. Fast transfer to any destination country anywhere.',
        moneyChangerParagraph1: 'Make sure you transact foreign exchange at an Authorized Money Changer that is safe and reliable. We are the right choice with very competitive transaction rates.',
        moneyChangerParagraph2: 'You will get a responsive and fast service. Supported by an integrated system, your foreign exchange transactions will be processed in real time.',
        tocTitle: "Terms and Condition.",
        tocHeader: 'Some Sentence about Terms and Condition Goes Here.',
        tocHeaderTitle: 'Terms & Condition',
        tocParagraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
        ]
      },
    ID: {
        value: 'Service-ID',
        freightServiceTitle: "Jasa Kargo.",
        freightServiceHeader: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) hadir melayani jasa pengiriman barang ke China, Bangkok, dan Singapura. Proses mudah, cepat, dan efisien.',
        freightServiceDescription: 'Melayani jasa pengangkutan impor melalui jalur udara dan laut. Kami juga sediakan kalkuator agar kalian bsa langsung dapat kuotasi harga dari kami',
        itemSourcingTitle: "Sourcing Barang.",
        itemSourcingHeader: `SSS Cargo (Cv. Semua Sahabat Sukses Cargo) adalah sahabat belanja terbaik di marketplace mancanegara, dengan proteksi ekstra. Kami bantu proses belanja sampai tuntas dan mengirimkannya ke lokasi Anda!`,
        itemSourcingDescription: 'Bersama kami, semua pembelanjaan menjadi sangat mudah. Menyediakan jasa trading/impor dari China, Bangkok, dan Singapura. Pelayanan terbaik dan terpercaya dalam pencarian barang, transaksi, cek kualitas dan kuantitas, hingga pengiriman barang',
        moneyChangerTitle: "Penukaran Uang.",
        moneyChangerHeader: 'SSS Cargo (Cv. Semua Sahabat Sukses Cargo) adalah solusi mudah bertransaksi valas. Memberikan rate terbaik, dengan biaya transfer yang rendah. Transfer cepat ke negara tujuan mana pun dimanapun.',
        moneyChangerParagraph1: 'Pastikan Anda bertransaksi valas di Authorized Money Changer yang aman dan terpercaya. Kami adalah pilihan tepat dengan kurs transaksi yang sangat kompetitif.',
        moneyChangerParagraph2: 'Anda akan mendapatkan pelayanan yang responsif dan cepat. Didukung sistem yang terintegrasi, transaksi valas Anda akan diproses secara real time.',
        tocTitle: "Syarat dan Ketentuan.",
        tocHeader: 'Kalimat tentang Syarat dan Ketentuan di sini.',
        tocHeaderTitle: 'Syarat & Ketentuan',
        tocParagraphs: [
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna. Blandit lorem turpis ipsum porttitor. Ultricies sed amet, donec est donec natoque.',
        ]
      }
}