import React, { useState, useEffect } from "react";


const ResponsiveImage = ({img, style, id = null, className = null, children}) => {

    return (
        <>
            <div id={id} className={className} style={{...style, backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat'}}>
                {children}
            </div>
        </>
    )
}

export default ResponsiveImage
